var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"dashboard__container--body"},[(_vm.clientContacts && _vm.clientContacts.length >= 1)?_c('div',{staticClass:"flex",staticStyle:{"width":"100%"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.clientContacts,"compactMode":"","search-options":{
              enabled: true,
              placeholder: 'Search this table',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3952757218)})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }